import { ORDER_EVALUATION } from '../../constants/select';
import {
  GET_SELECTION_TAGS_SUCCESS,
  GET_SELECTION_TAGS_REQUEST_START_LOADING,
  GET_SELECTION_TAGS_REQUEST_END_LOADING,
  GET_SELECTIONS_BY_PARAMS_SUCCESS,
  GET_SELECTIONS_BY_PARAMS_REQUEST_START_LOADING,
  GET_SELECTIONS_BY_PARAMS_REQUEST_END_LOADING,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_START_LOADING,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_STOP_LOADING,
  GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_SUCCESS,
  ADD_TO_CART_REQUEST_START_LOADING,
  ADD_TO_CART_REQUEST_END_LOADING,
  GET_SELECTION_MODAL_INFO_SUCCESS,
  GET_SELECTION_MODAL_INFO_REQUEST_START_LOADING,
  GET_SELECTION_MODAL_INFO_REQUEST_STOP_LOADING,
  CLEAR_SELECTION_MODAL_INFO,
  CREATE_SELECTION_AP_GROUP_REQUEST_START_LOADING,
  CREATE_SELECTION_AP_GROUP_REQUEST_END_LOADING,
  CHANGE_SELECTED_INSTRUMENT_ID,
  CHANGE_SELECTED_TERM_ID,
  GET_SELECTION_MODAL_TERM_INFO_REQUEST_START_LOADING,
  GET_SELECTION_MODAL_TERM_INFO_REQUEST_STOP_LOADING,
  SET_FILTER_KEYWORD,
  REMOVE_FILTER_KEYWORD,
  SAVE_FILTER_CONDITION,
  CLEAR_FILTER_CONDITION,
  CHANGE_PARENT_TAG_ID,
  SET_INITIAL_TAG,
  REMOVE_INITAL_TAG,
  SAVE_SORT_ORDER,
  CLEAR_SORT_ORDER,
  SAVE_SEARCH_AUTO_SELECT_DATA,
  CLEAR_SEARCH_AUTO_SELECT_DATA,
} from '../actionConstants/autoSelectConstants';

export const initialState = {
  selectionTags: [],
  areSelectionTagsLoading: false,
  activeSelections: [],
  areActiveSelectionsLoading: false,
  addToCartRequestInfo: {
    id: null,
    loading: false,
  },
  selectionModalInfo: {},
  selectionModalInfoIsLoading: false,
  selectionModalTermInfoIsLoading: false,
  createSelectionApGroupRequestIsLoading: false,
  addToCartStrategyInfo: {
    loading: false,
    strategyList: null,
  },
  selectedAutoSelect: {
    selectedServiceId: null,
    selectedInstrumentId: null,
    selectedStrategyId: null,
  },
  filterCondition: {},
  sortOrder: ORDER_EVALUATION,
  initialSearchText: '',
  parentTagId: null,
  initialChildTag: {
    mode: 'fullName',
    fullName: null,
  },
  searchAutoSelectData: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SELECTION_TAGS_SUCCESS: {
      const { selectionTagsData } = payload;
      return {
        ...state,
        selectionTags: selectionTagsData,
      };
    }
    case GET_SELECTION_TAGS_REQUEST_START_LOADING: {
      return {
        ...state,
        areSelectionTagsLoading: true,
      };
    }
    case GET_SELECTION_TAGS_REQUEST_END_LOADING: {
      return {
        ...state,
        areSelectionTagsLoading: false,
      };
    }
    case GET_SELECTIONS_BY_PARAMS_SUCCESS: {
      const { selectionsData } = payload;
      return {
        ...state,
        activeSelections: selectionsData,
      };
    }
    case GET_SELECTIONS_BY_PARAMS_REQUEST_START_LOADING: {
      return {
        ...state,
        areActiveSelectionsLoading: true,
      };
    }
    case GET_SELECTIONS_BY_PARAMS_REQUEST_END_LOADING: {
      return {
        ...state,
        areActiveSelectionsLoading: false,
      };
    }
    case GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_START_LOADING: {
      return {
        ...state,
        addToCartStrategyInfo: {
          ...state.addToCartStrategyInfo,
          loading: true,
        },
      };
    }
    case GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_STOP_LOADING: {
      return {
        ...state,
        addToCartStrategyInfo: {
          ...state.addToCartStrategyInfo,
          loading: false,
        },
      };
    }
    case GET_SELECTION_FOR_VALIDATING_ADD_TO_CART_SUCCESS: {
      const { strategyList } = payload;
      return {
        ...state,
        addToCartStrategyInfo: {
          ...state.addToCartStrategyInfo,
          strategyList,
        },
      };
    }
    case ADD_TO_CART_REQUEST_START_LOADING: {
      const { id } = payload;
      return {
        ...state,
        addToCartRequestInfo: {
          id,
          loading: true,
        },
      };
    }
    case ADD_TO_CART_REQUEST_END_LOADING: {
      return {
        ...state,
        addToCartRequestInfo: {
          id: null,
          loading: false,
        },
      };
    }
    case GET_SELECTION_MODAL_INFO_SUCCESS: {
      const { selectionModalInfo, termId } = payload;
      return {
        ...state,
        selectionModalInfo: {
          ...state.selectionModalInfo,
          [termId]: selectionModalInfo,
        },
      };
    }
    case CLEAR_SELECTION_MODAL_INFO: {
      return {
        ...state,
        selectionModalInfo: {},
      };
    }
    case GET_SELECTION_MODAL_INFO_REQUEST_START_LOADING: {
      return {
        ...state,
        selectionModalInfoIsLoading: true,
      };
    }
    case GET_SELECTION_MODAL_INFO_REQUEST_STOP_LOADING: {
      return {
        ...state,
        selectionModalInfoIsLoading: false,
      };
    }
    case GET_SELECTION_MODAL_TERM_INFO_REQUEST_START_LOADING: {
      return {
        ...state,
        selectionModalTermInfoIsLoading: true,
      };
    }
    case GET_SELECTION_MODAL_TERM_INFO_REQUEST_STOP_LOADING: {
      return {
        ...state,
        selectionModalTermInfoIsLoading: false,
      };
    }
    case CREATE_SELECTION_AP_GROUP_REQUEST_START_LOADING: {
      return {
        ...state,
        createSelectionApGroupRequestIsLoading: true,
      };
    }
    case CREATE_SELECTION_AP_GROUP_REQUEST_END_LOADING: {
      return {
        ...state,
        createSelectionApGroupRequestIsLoading: false,
      };
    }
    case CHANGE_SELECTED_INSTRUMENT_ID: {
      const { instrumentId, strategyId, serviceId } = payload;
      return {
        ...state,
        selectedAutoSelect: {
          selectedServiceId: serviceId,
          selectedInstrumentId: instrumentId,
          selectedStrategyId: strategyId,
        },
      };
    }
    case CHANGE_SELECTED_TERM_ID: {
      const { termId } = payload;
      return {
        ...state,
        selectedTermId: termId,
      };
    }
    case SET_FILTER_KEYWORD: {
      const { keyword } = payload;
      return {
        ...state,
        initialSearchText: keyword,
      };
    }
    case REMOVE_FILTER_KEYWORD: {
      return {
        ...state,
        initialSearchText: '',
      };
    }
    case SET_INITIAL_TAG: {
      const { mode = 'fullName', fullName = null } = payload;
      return {
        ...state,
        initialChildTag: { mode, fullName },
      };
    }
    case REMOVE_INITAL_TAG: {
      return {
        ...state,
        initialChildTag: {
          mode: 'fullName',
          fullName: null,
        },
      };
    }
    case SAVE_FILTER_CONDITION: {
      const { filterCondition } = payload;
      return {
        ...state,
        filterCondition,
      };
    }
    case CLEAR_FILTER_CONDITION:
      return {
        ...state,
        filterCondition: {},
      };
    case SAVE_SORT_ORDER: {
      const { sortOrder } = payload;
      return {
        ...state,
        sortOrder,
      };
    }
    case CLEAR_SORT_ORDER:
      return {
        ...state,
        sortOrder: initialState.sortOrder,
      };
    case CHANGE_PARENT_TAG_ID: {
      const { parentTagId } = payload;
      return {
        ...state,
        parentTagId,
      };
    }
    case SAVE_SEARCH_AUTO_SELECT_DATA: {
      const { search } = payload;
      return {
        ...state,
        searchAutoSelectData: search,
      };
    }
    case CLEAR_SEARCH_AUTO_SELECT_DATA: {
      return {
        ...state,
        searchAutoSelectData: null,
      };
    }
    default: {
      return state;
    }
  }
};
