/* eslint-disable-next-line import/no-unresolved */
import { createElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
/* eslint-disable-next-line import/no-unresolved */
import PropTypes from 'prop-types';
import { groupBy, isUndefined } from 'lodash';
import {
  createAssetFilter,
  createStatusFilter,
  createTypeFilter,
  createInstrumentFilter,
  useIsMarginZeroByServiceId,
  useCalculateUnrealizedProfitLossForPortfolioCard,
} from '../services/hooks';
import { checkIsWebApp, getDefaultValuesFromLocalStorage, add } from '../services';
import { compareServiceId, getServiceId, makeParentKey } from '../utils';
import { AP_GROUP_SOURCES, CFD, ETF, FX } from '../constants';
import { KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS } from '../constants/portfolio';
import { ERROR_FIELD_REQUIRED, ERROR_TITLE } from '../constants/errorMesages';
import { useServiceName } from './useServiceName';
import { useMeterAttention } from './balanceMeter';
import { useAccountInfo } from './useAccountInfo';
import { useRemovedDuplicateInstrumentOptions } from './symbol';
import { getPortfolioDataRequest, openErrorInfoModal, updateApGroupRequest } from '../redux/actions';

const aggregate = (items, instrumentList) => {
  const grouped = groupBy(items, makeParentKey);
  const singles = Object.entries(grouped)
    .filter(([_groupKey, children]) => children.length === 1)
    .map(([_groupKey, children]) => {
      const [{ currency, ...restItem }] = children;
      return { ...restItem, currency, serviceIds: [getServiceId(currency, instrumentList)] };
    });
  return [
    Object.entries(grouped)
      .filter(([_groupKey, children]) => children.length !== 1)
      .map(([groupKey, children]) => {
        const result = children.reduce(
          (accumulator, currentValue) => ({
            count: add(accumulator.count, currentValue.count),
            activeCount: add(accumulator.activeCount, currentValue.activeCount),
            realizedProfitLoss: add(accumulator.realizedProfitLoss, currentValue.realizedProfitLoss),
          }),
          {
            count: 0,
            activeCount: 0,
            realizedProfitLoss: 0,
          },
        );
        const [first] = children || [];
        const serviceIds = [...new Set(children.map(({ currency }) => getServiceId(currency, instrumentList)))];
        serviceIds.sort(compareServiceId);
        const { key, sourceType, parentImage, parentName } = first || {};
        return {
          ...first,
          ...result,
          // フォーマットはオリジナルの key に合わせているが、一意であればなんでも良いはず
          key: `${key} - ${groupKey}`,
          sourceType,
          parentImage,
          parentName,
          currency: '-',
          children,
          serviceIds,
        };
      }),
    singles,
  ];
};

export const useFilter = ({ assetTypeFilter, tradeTypeFilter, statusFilter, instrumentFilter }) => {
  return useMemo(() => {
    const assetFilterFunc = createAssetFilter(assetTypeFilter);
    const tradeTypeFilterFunc = createTypeFilter(tradeTypeFilter);
    const statusFilterFunc = createStatusFilter(statusFilter);
    const instrumentFilterFunc = createInstrumentFilter(instrumentFilter.value);

    return {
      assetFilterFunc,
      tradeTypeFilterFunc,
      statusFilterFunc,
      instrumentFilterFunc,
    };
  }, [assetTypeFilter, tradeTypeFilter, statusFilter, instrumentFilter]);
};

export const useFilterFunc = (filters) => {
  return useCallback((item) => filters.every((filter) => filter(item)), [filters]);
};

// compose a filter for an array of group
export const useGroupFilter = (filters) => {
  const childFilter = useFilterFunc(filters);
  // for each group, return true if any of its children passes all the filters
  return useCallback((group) => group.children && group.children.some((child) => childFilter(child)), [childFilter]);
};

export const useGroupedPortfolios = ({ isGrouped, portfolios, techApGroups }) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useMemo(() => {
    let grouped = [];
    let list = portfolios || [];
    if (isGrouped) {
      let singles;
      [grouped, singles] = aggregate(
        list.filter(({ parentId }) => parentId != null && parentId !== ''),
        instrumentList,
      );
      const rest = list
        .filter(({ parentId }) => parentId == null || parentId === '')
        // technical builder 由来のものには parentId が設定されていないので sourceType で判定
        .filter(({ sourceType }) => sourceType !== AP_GROUP_SOURCES.TECH.KEY);
      list = rest.concat(singles).concat(techApGroups);
    }

    return [grouped, list];
  }, [isGrouped, portfolios, techApGroups, instrumentList]);
};

export const initInstrumentOptions = (instrumentOptions, serviceId) => {
  if (instrumentOptions == null) {
    return [];
  }
  return instrumentOptions.map(({ checked, ...rest }) => {
    const option = { ...rest, checked: isUndefined(checked) ? true : checked };
    if (serviceId) {
      return { ...option, serviceId };
    }
    return option;
  });
};

export const useInstrumentOptions = () => {
  const fxSavedInstruments = useRemovedDuplicateInstrumentOptions(FX);
  const etfSavedInstruments = useSelector((state) => state.constants[ETF].instrumentsOptions);
  const cfdSavedInstruments = useRemovedDuplicateInstrumentOptions(CFD);

  const fxInstruments = useMemo(() => initInstrumentOptions(fxSavedInstruments), [fxSavedInstruments]);
  const etfInstruments = useMemo(() => initInstrumentOptions(etfSavedInstruments), [etfSavedInstruments]);
  const cfdInstruments = useMemo(() => initInstrumentOptions(cfdSavedInstruments), [cfdSavedInstruments]);

  const defaultOptions = useMemo(
    () => [
      ...fxInstruments.map((item) => ({ ...item, serviceId: FX })),
      ...etfInstruments.map((item) => ({ ...item, serviceId: ETF })),
      ...cfdInstruments.map((item) => ({ ...item, serviceId: CFD })),
    ],
    [etfInstruments, fxInstruments, cfdInstruments],
  );

  let instrumentsOptions = defaultOptions;
  if (checkIsWebApp()) {
    instrumentsOptions = JSON.parse(
      getDefaultValuesFromLocalStorage({
        key: KEY_FOR_SELECTED_PORTFOLIO_INSTRUMENTS,
        defaultValue: JSON.stringify(defaultOptions),
      }),
    );
    if (instrumentsOptions.length === defaultOptions.length) {
      return instrumentsOptions;
    }
  }

  return defaultOptions;
};

export const useDangerLevelNotificationCard = ({ serviceId, data }) => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const accountInfo = useAccountInfo()[serviceId];
  const serviceName = useServiceName(serviceId);
  let isMarginZero = useIsMarginZeroByServiceId(serviceId);
  isMarginZero = isAuth ? isMarginZero : false;
  const { needWarningIcon } = useMeterAttention(data);
  return useMemo(
    () => ({
      hide: (isMarginZero && !accountInfo.isNotAvailable) || !needWarningIcon,
      message: `${serviceName}証拠金の余力が少ないです`,
      label: '入金する',
    }),
    [isMarginZero, needWarningIcon, serviceName, accountInfo.isNotAvailable],
  );
};

export const useAutoReloadPortfolioData = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const accountInfo = useAccountInfo();
  const isNotAvailableFX = accountInfo[FX].isNotAvailable;
  const isNotAvailableETF = accountInfo[ETF].isNotAvailable;
  const isNotAvailableCFD = accountInfo[CFD].isNotAvailable;
  useEffect(() => {
    if (isAuth) {
      dispatch(getPortfolioDataRequest());
    }
  }, [dispatch, isAuth, isNotAvailableFX, isNotAvailableETF, isNotAvailableCFD]);
};

const getApGroupKey = (apGroup) => `${apGroup.serviceId}_${apGroup.id}`;

const UnrealizedProfitLossObserver = memo(({ apGroup, onChange }) => {
  const key = useMemo(() => getApGroupKey(apGroup), [apGroup]);
  const { currency, id, buySell } = apGroup;
  const unrealizedProfitLoss = useCalculateUnrealizedProfitLossForPortfolioCard({
    instrumentId: currency,
    apGroupId: id,
    buySell,
  });
  useEffect(() => {
    onChange(key, unrealizedProfitLoss);
  }, [key, unrealizedProfitLoss, onChange]);
  return null;
});

UnrealizedProfitLossObserver.propTypes = {
  apGroup: PropTypes.shape({
    id: PropTypes.string,
    serviceId: PropTypes.string,
    currency: PropTypes.string,
    apGroupId: PropTypes.string,
    buySell: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const getIsTechCalc = (apGroup) =>
  !apGroup.id && apGroup.sourceType === AP_GROUP_SOURCES.TECH.KEY && apGroup.apGroupIdList.length > 0;

export const useUnrealizedProfitLossForParentGroup = (apGroup) => {
  const [unrealizedPLs, setUnrealizedPLs] = useState({});

  const needParentCalculation = useMemo(() => {
    const { children } = apGroup;
    return !getIsTechCalc(apGroup) && children != null && children.length > 1;
  }, [apGroup]);

  const onChange = useCallback(
    (key, newValue) => {
      if (needParentCalculation) {
        setUnrealizedPLs((oldValue) => ({ ...oldValue, [key]: newValue }));
      }
    },
    [needParentCalculation],
  );

  const unrealizedProfitLoss = useMemo(() => {
    const filtered = Object.values(unrealizedPLs).filter((v) => v != null && v !== '-');
    if (filtered.length === 0) {
      return '-';
    }
    return filtered.reduce((acc, curr) => acc + curr, 0);
  }, [unrealizedPLs]);

  const observer = useMemo(() => {
    if (needParentCalculation) {
      // 子供の未実現損益を算出するための Observer コンポーネント(非表示)を生成する
      return apGroup.children.map((child) =>
        createElement(UnrealizedProfitLossObserver, {
          key: getApGroupKey(child),
          apGroup: child,
          onChange,
        }),
      );
    }
    return null;
  }, [needParentCalculation, apGroup, onChange]);

  return { needParentCalculation, unrealizedProfitLoss, observer };
};

export const useUpdateApGroupNameHandler = ({ serviceId, groupId, extractor }) => {
  const dispatch = useDispatch();
  return useCallback(
    (value) => {
      const apGroupName = extractor(value);
      // Java の trim と違い、全角スペースも対象に含まれることに注意
      // API側が全角空白を含むものをエラーとするため、検証時に全角空白がトリムされることは許容する
      if (apGroupName == null || apGroupName.trim() === '') {
        dispatch(openErrorInfoModal({ message: `グループ名${ERROR_FIELD_REQUIRED}`, title: ERROR_TITLE }));
      } else {
        dispatch(updateApGroupRequest({ serviceId, groupId, apGroupName }));
      }
    },
    [dispatch, serviceId, groupId, extractor],
  );
};
