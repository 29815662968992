import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './bootstrap.css';
import './index.scss';
import './common.scss';
import smoothscroll from 'smoothscroll-polyfill';
/* eslint-disable no-unused-vars */
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_WEB_API_KEY, BUGSNAG_WEB_RELEASE_STAGE, DEBUG, STAGE } from 'shared-modules/config';
import { BUGSNAG_ENABLED_RELEASE_STAGES_LOTUS, BUGSNAG_RELEASE_STAGE_POSTFIX } from 'shared-modules/constants';
import Logger from 'shared-modules/services/Logger';
/* eslint-enable no-unused-vars */
import productionJSON from 'shared-modules/app.prd.json';
import stagingJSON from 'shared-modules/app.stg.json';
import developmentJSON from 'shared-modules/app.dev.json';

import App from './App';
import { AppCssVariablesProvider } from './contexts';

let configFile;
switch (STAGE) {
  case 'DEVELOPMENT': {
    configFile = developmentJSON;
    break;
  }
  case 'STAGING': {
    configFile = stagingJSON;
    break;
  }
  case 'PRODUCTION': {
    configFile = productionJSON;
    break;
  }
  default: {
    // empty
  }
}
const appVersion = configFile?.expo?.version ? `v${configFile?.expo?.version}` : '';
const otaVersion = configFile?.expo?.extra?.otaVersion ? ` (${configFile?.expo?.extra?.otaVersion})` : '';
const versionString = `${appVersion}${otaVersion}`;

// Initialize Bugsnag
let ErrorBoundary;
if (BUGSNAG_WEB_API_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_WEB_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    appVersion: versionString,
    enabledReleaseStages: BUGSNAG_ENABLED_RELEASE_STAGES_LOTUS,
    releaseStage: `${BUGSNAG_WEB_RELEASE_STAGE}${BUGSNAG_RELEASE_STAGE_POSTFIX}`,
  });

  ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

  Logger.onErrorReport((error, onError) => {
    Bugsnag.notify(error, onError);
  });
}

smoothscroll.polyfill();

if (DEBUG) {
  console.log(`STAGE: ${STAGE}, version: ${versionString}`); // eslint-disable-line
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <AppCssVariablesProvider root={rootElement}>
      {ErrorBoundary ? (
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      ) : (
        <App />
      )}
    </AppCssVariablesProvider>
  </React.StrictMode>,
);
