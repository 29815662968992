import Decimal from 'decimal.js';
import moment from 'moment-timezone';
import { isNumber } from 'lodash';
import {
  BUY_SELL_MAIN,
  BALANCE_METER_ATTENTION,
  CART_DEFAULT_SETS,
  CHART_DATA_FORMAT,
  COUNTER_PRECISION,
  COUNTRY_TYPE,
  EMAIL_LIST_TEXTS,
  ETF,
  EXPIRATION_TYPE_MAIN,
  EXPIRATION_TYPE_VALUES,
  FRONT_ZEROS_AND_COMMAS_REG,
  FX,
  INSTRUMENT_PRICE_STEP_WITHOUT_JPY,
  INSTRUMENT_PRICE_STEP_WTH_JPY,
  KEY_FOR_SYSTEM_ERROR,
  MAIL_ACTIONS,
  MIN_REPORTS_YEAR_ETF,
  MIN_REPORTS_YEAR_FX,
  NUMBER_VALIDATION_REG,
  PRICE_MAX_MULTIPLIER,
  PRICE_MIN_MULTIPLIER,
  QUANTITY_COUNTER_MIN_INT,
  QUANTITY_PRECISION,
  QUANTITY_STEP,
  quarterOptions,
  SATURDAY_ID,
  SPREAD_MAX_VALUE,
  SPREAD_MORE_THEN_MAX_VALUE,
  SPREAD_NOT_YEN_PAIR_MULTIPLIER,
  SPREAD_YEN_PAIR_MULTIPLIER,
  TRADE_METHODS,
  VALIDATION_ERROR_AP_ORDER_QUANTITY_ONE,
  VALIDATION_ERROR_AP_ORDER_QUANTITY_ZERO_POINT_ONE,
  VALIDATION_ERROR_EMPTY_FIELD,
  CFD,
  QUANTITY_MAXES,
  QUANTITY_MINS,
  MIN_REPORTS_YEAR_CFD,
  AMOUNT_UNIT_MAP,
  INSTRUMENT_ZAR_AP_JPY,
  FX_AP_GROUP_CHANGE_LOGIC_STEP_CROSS_CURRENCY,
  FX_AP_GROUP_CHANGE_LOGIC_STEP_CROSS_YEN,
  FX_CFD_AP_GROUP_CHANGE_LOGIC_STEP,
} from '../constants';
import { CFD_MIN_VALUE, ENTRY_PRICE_NOT_PAIRED_YEN_STEP, ENTRY_PRICE_PAIRED_YEN_STEP } from '../constants/builder';
import {
  CHECK_USER_AUTHENTICATED_ERROR,
  DISPLAYING_ERROR_LOGIN,
  IMS_200A,
  IMS_200A_ERROR_MESSAGE,
  IMS_200B,
  IMS_200B_ERROR_MESSAGE,
  IMS_201,
  IMS_201_ERROR_MESSAGE,
  IMS_202,
  IMS_202_ERROR_MESSAGE,
  IMS_203,
  IMS_203_ERROR_MESSAGE,
  IMS_800,
  IMS_800_ERROR_MESSAGE,
  IMS_REG,
  IMS_XXX_ERROR_MESSAGE,
  INNER_ERROR_VALIDATION_ERRORS,
} from '../constants/errorMesages';
import {
  COUNT_INPUT,
  DEFAULT_QUANTITY_MULTIPLIERS,
  EXPIRATION_TYPE_INPUT,
  KEY_FOR_DEFAULT_SELECT_SIDE,
  LIMIT_PRICE_INPUT,
  ORDER_TYPES,
  PRICE_INPUT,
  SETTLEMENT_EXPIRATION_TYPE_INPUT,
  SETTLEMENT_LIMIT_PRICE_INPUT,
  SETTLEMENT_PRICE_INPUT,
  SETTLEMENT_STOP_PRICE_INPUT,
  SORT_ASCENDING,
  SORT_DESCENDING,
  STOP_PRICE_INPUT,
} from '../constants/manualTrade';
import {
  changeCreateOrderValues,
  changeSelectedSide,
  resetCreateOrderNonCommonValues,
  resetCreateOrderValues,
} from '../redux/actions/manualTradeActions';
import { getService, getSpreadPrecision, numberExists, selectByServiceId, removeInstrumentIdSuffix } from '../utils';
import { addSuffixInstrumentId } from '../hooks/symbol';

export { validateName, getValidationResult } from './validators';

moment.tz.setDefault('Asia/Tokyo');

export const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

// todo: change when add server response
export const parseLoginError = ({ message, errorMessages }) => {
  switch (message) {
    case INNER_ERROR_VALIDATION_ERRORS: {
      return errorMessages;
    }
    case CHECK_USER_AUTHENTICATED_ERROR:
    default: {
      return [];
    }
  }
};

const isNan = (value) => !Number.isFinite(Number(value));

export const createFlooredAccessor = (field) => (row) => {
  const value = row[field];
  if (isNan(value)) return value;

  return Math.floor(row[field]);
};

export const toCommaSeparatedNumber = (num) => {
  const number = String(num);
  const pointIndex = number.indexOf('.');
  return number.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => (pointIndex < 0 || i < pointIndex ? `${m},` : m));
};

export const formatNumberToDisplayedString = ({ value, withoutPlus = false, withYenIcon = true, symbol = '' }) => {
  if (!Number.isFinite(Number(value))) return value;

  const plusPrefix = value > 0 && !withoutPlus ? '+' : '';
  const returnedValue =
    value === 0 || value === null ? '-' : `${toCommaSeparatedNumber(value)}${withYenIcon ? ' 円' : symbol}`;
  return `${plusPrefix}${returnedValue}`;
};

export const formatPrice = ({ value, withoutPlus = false, allowZero = false, emptyString = '-' }) => {
  let isEmpty = value == null || value === '';
  // eslint-disable-next-line eqeqeq
  const isZero = value == 0;
  if (!allowZero) {
    isEmpty ||= isZero;
  }
  const num = Number(value ?? 0);
  if (!Number.isFinite(num)) {
    // フォーマット済みとみなす
    return [value, true];
  }
  const plusPrefix = num > 0 && !withoutPlus ? '+' : '';
  const returnedValue = isEmpty ? emptyString : toCommaSeparatedNumber(value);
  return [`${plusPrefix}${returnedValue}`, isEmpty];
};

export const formatNumberToDisplayedStringAllowZero = ({
  value,
  withoutPlus = false,
  withYenIcon = true,
  symbol = '',
}) => {
  if (!Number.isFinite(Number(value))) return value;

  const plusPrefix = value > 0 && !withoutPlus ? '+' : '';
  let returnedValue;

  if (value === 0 || value === '0') returnedValue = '+0円';
  else returnedValue = value === null ? '-' : `${toCommaSeparatedNumber(value)}${withYenIcon ? '円' : symbol}`;

  return `${plusPrefix}${returnedValue}`;
};

export const formatNumberZeroEn = ({ value, withoutPlus = false, withYenIcon = true, symbol = '' }) => {
  if (!Number.isFinite(Number(value))) return value;

  const plusPrefix = value > 0 && !withoutPlus ? '+' : '';
  let returnedValue;

  if (value === 0 || value === '0') returnedValue = '0円';
  else returnedValue = value === null ? '-' : `${toCommaSeparatedNumber(value)}${withYenIcon ? '円' : symbol}`;

  return `${plusPrefix}${returnedValue}`;
};

export const formatNumberToPercentageString = (value) => {
  if (!Number.isFinite(value)) {
    return value;
  }
  return `${value > 0 ? '+' : ''}${value}%`;
};

export const checkForInteger = (value) => {
  if (Number.isNaN(+value) || +value % 1 !== 0) return value;
  return Math.round(+value);
};

export const checkIsDST = (date) => moment(date).tz('America/New_York').isDST();

export const getMaxDate = (datesArray) => {
  if (!datesArray?.length) return null;
  const momentMappedArray = datesArray.map((date) => moment(date));
  return moment.max(momentMappedArray).format();
};

export const getJapaneseFormatDate = (date) => moment(date).format('YYYY年MM月DD日');

export const createDateStringByDateAndTime = (date, time) => {
  const [hours, minutes] = String(time).split(':');
  return moment(date).hour(Number(hours)).minute(Number(minutes)).second(0).toDate();
};

export const getStartMinuteTime = (time) => moment(time).second(0);

export const getTimeSecondsDifference = (firstTime, secondTime) =>
  moment(firstTime).diff(moment(secondTime), 'seconds');

export const getTimeYearsDifference = (firstTime, secondTime) => moment(firstTime).diff(moment(secondTime), 'year');

export const isMoreThanOneYearAgo = (date) => {
  const currentTime = new Date();
  const minDate = new Date(currentTime.setFullYear(currentTime.getFullYear() - 1));
  return date <= minDate;
};

const getEndOfDay = (serviceId) => {
  const checkIsSaturday = (date) => moment(date).isoWeekday() === SATURDAY_ID;

  const getEndOfDayLocal = (date) => {
    const isSaturday = checkIsSaturday(date);
    const isDST = checkIsDST(date);

    // FX DST ~ 5:50(Sut ~ 5:00)
    // FX nonDST ~ 6:50(Sut ~ 6:00)
    const fxDSTHours = isSaturday ? 4 : 5;
    const fxNonDSTHours = isSaturday ? 5 : 6;
    const fxHours = isDST ? fxDSTHours : fxNonDSTHours;
    const fxMinutes = isSaturday ? 59 : 39;
    // CFD DST ~ 5:40
    // CFD nonDST ~ 6:40
    const cfdHours = isDST ? 5 : 6;
    const cfdMinutes = 39;
    // ETF DST ~ 4:55
    // ETF nonDST ~ 5:55
    const etfHours = isDST ? 4 : 5;
    const etfMinutes = 54;

    const hours = selectByServiceId(serviceId, fxHours, cfdHours, etfHours);
    const minutes = selectByServiceId(serviceId, fxMinutes, cfdMinutes, etfMinutes);

    return moment.tz(date, 'Asia/Tokyo').set({
      hour: hours,
      minute: minutes,
      second: 59,
    });
  };

  const currentMoment = moment().tz('Asia/Tokyo');
  const endOfCurrentDay = getEndOfDayLocal(currentMoment);

  const isSameOrAfter = currentMoment.isSameOrAfter(endOfCurrentDay);

  if (isSameOrAfter) {
    const nextDay = currentMoment.add(1, 'day');
    const endOfNextDay = getEndOfDayLocal(nextDay);

    return endOfNextDay.toDate();
  }

  return endOfCurrentDay.toDate();
};

const getEndOfWeekHour = (serviceId, currentTime) => {
  if (serviceId === CFD) {
    return checkIsDST(currentTime) ? 5 : 6;
  }
  return checkIsDST(currentTime) ? 4 : 5;
};

const getEndOfWeek = (serviceId) => {
  const currentTime = moment();
  const saturdayTime = moment()
    .isoWeekday(SATURDAY_ID)
    .hour(getEndOfWeekHour(serviceId, currentTime))
    .minute(selectByServiceId(serviceId, 59, 39, 54))
    .second(59);
  if (getTimeSecondsDifference(saturdayTime, currentTime) < 0) {
    saturdayTime.add(1, 'week');
  }
  return saturdayTime.toDate();
};

export const getTimeValueByExpirationType = (type, time, serviceId) => {
  switch (type) {
    case EXPIRATION_TYPE_MAIN.DAY.ID: {
      return getEndOfDay(serviceId);
    }
    case EXPIRATION_TYPE_MAIN.WEEK.ID: {
      return getEndOfWeek(serviceId);
    }
    case EXPIRATION_TYPE_MAIN.CUSTOM.ID: {
      return time;
    }
    default: {
      return time;
    }
  }
};

export const getRoundedPlusOneHourCurrentTime = () => moment().add(2, 'hour').startOf('hour').format('HH:mm');

export const getRoundedPlusOneHourCurrentDate = () => {
  const date = moment().add(2, 'hour').startOf('hour');
  if (date.day() === 6) {
    date.add(2, 'day');
  } else if (date.day() === 0) {
    date.add(1, 'day');
  }
  return date.format();
};

// todo: temp changes, need rebuild all date formatting functions
export const createDateString = (date, time, withoutDivider = false) =>
  `${moment(date).format(withoutDivider ? 'YYYY/MM/DD' : 'YYYY-MM-DD')}${withoutDivider ? ' ' : 'T'}${time}`;
export const createCurrentDateString = () => `${moment().tz('Asia/Tokyo').format('YYYY-MM-DD[T]HH:mm:ss')}`;
export const createCurrentDateAddSecondString = (dateTime, second) =>
  `${moment(dateTime).add(second, 's').format('YYYY-MM-DD[T]HH:mm:ss')}`;

export const formatDateTimeWithoutDivider = (dateTime) =>
  dateTime ? moment(dateTime).format('YYYY/MM/DD HH:mm:ss') : '';

export const formatDateTimeShortTypeWithoutSecond = (dateTime) =>
  dateTime ? moment(dateTime).format('YY/M/D H:mm') : '';

export const convertSlashAndColonToISO = (dateTimeString) => {
  return dateTimeString ? moment.tz(dateTimeString, 'YYYY/MM/DD HH:mm:ss', 'Asia/Tokyo').toISOString() : '';
};

export const formatDateTimeWithoutYearsAndSecond = (dateTime) =>
  dateTime ? moment(dateTime).format('MM/DD HH:mm') : '';
export const formatDateTimeWithoutYears = (dateTime) => (dateTime ? moment(dateTime).format('MM/DD HH:mm:ss') : '');

export const getDateSolidString = () => moment().format('YYYYMMDDHHmmss');

// for backend requests
export const createDateStringWithoutTime = (date) => moment(date).format('YYYY-MM-DD');

export const getSlashedDateStringWithoutTime = (date) => moment(date).format('YYYY/MM/DD');
export const getSlashedDateStringMM = (date) => moment(date).format('YYYY/MM');

export const getLastDayInMonthDate = (date) => moment(date).add(1, 'months').date(0).format();

export const date30DaysBefore = () => new Date(moment().subtract(30, 'days').format());

export const date3MonthBefore = () => new Date(moment().subtract(3, 'months').format());

export const checkIfWeekday = (date, allowSaturdays = false) => {
  const day = moment(date).day();
  if (allowSaturdays) return day !== 0;
  return day !== 0 && day !== 6;
};

export const getNearestWorkday = (date) => {
  if (checkIfWeekday(date)) {
    return new Date(date.format());
  }
  return new Date(date.subtract(6, 'days').day(5).format());
};

export const getPreviousWorkday = (date) => {
  const previousDayDate = moment(date).subtract(1, 'day');
  return getNearestWorkday(previousDayDate);
};

export const getPreviousMonthWorkday = () => {
  const previousMonthDate = moment().subtract(1, 'months');
  return getNearestWorkday(previousMonthDate);
};

export const getPreviousYearWorkday = () => {
  const previousYearDate = moment().subtract(1, 'year');
  return getNearestWorkday(previousYearDate);
};

export const checkIsWebApp = () => Boolean(window.localStorage);

export const getDefaultValuesFromLocalStorage = ({ key, defaultValue }) => {
  if (!checkIsWebApp()) {
    return defaultValue;
  }
  return localStorage.getItem(key) || defaultValue;
};

export const saveDefaultValuesFromLocalStorage = ({ key, value }) => {
  if (!checkIsWebApp()) {
    return;
  }
  localStorage.setItem(key, value);
};

export const removeKeyFromLocalStorage = ({ key }) => {
  if (!checkIsWebApp()) {
    return;
  }
  localStorage.removeItem(key);
};

export const getDefaultValuesFromJsonOfLocalStorage = ({ key, defaultValue, localStorageKey }) => {
  const storageObj = getDefaultValuesFromLocalStorage({
    key: localStorageKey,
    defaultValue: {},
  });

  if (!Object.keys(storageObj).length) {
    return defaultValue;
  }
  return JSON.parse(storageObj)[key] ?? defaultValue;
};

export const saveJsonStyleDefaultValuesToLocalStorage = ({ key, value, localStorageKey }) => {
  let storageObj = getDefaultValuesFromLocalStorage({
    key: localStorageKey,
    defaultValue: {},
  });

  const saveObj = key !== 'execTime' ? { [key]: value } : { fromDate: value?.fromDate, toDate: value?.toDate };

  storageObj = Object.keys(storageObj).length
    ? { ...JSON.parse(storageObj), ...saveObj }
    : {
        ...saveObj,
      };

  saveDefaultValuesFromLocalStorage({
    key: localStorageKey,
    value: JSON.stringify(storageObj),
  });
};

export const getDefaultValuesFromSessionStorage = ({ key, defaultValue }) => {
  if (!checkIsWebApp()) {
    return defaultValue;
  }
  return sessionStorage.getItem(key) || defaultValue;
};

export const saveDefaultValuesFromSessionStorage = ({ key, value }) => {
  if (!checkIsWebApp()) {
    return;
  }
  sessionStorage.setItem(key, value);
};

export const removeKeyFromSessionStorage = ({ key }) => {
  if (!checkIsWebApp()) {
    return;
  }
  sessionStorage.removeItem(key);
};

export const getPrecisionTriggeringReg = (precision) => {
  if (!precision || precision < 1) {
    return new RegExp();
  }
  const precisionLength = String(precision).length;
  return new RegExp(`\\.\\d{${precisionLength},}$`);
};

export const roundToPrecision = (value, precision) => {
  if (!Number.isFinite(value) || !Number.isFinite(precision)) {
    return value;
  }
  return Math.floor(value * precision) / precision;
};

export const roundToFixedPrecision = (value, precision) => {
  if (!Number.isFinite(value) || !Number.isFinite(precision) || precision <= 0) {
    return value;
  }
  if (precision >= 1) {
    return Math.trunc(value);
  }

  return Number(value).toFixed(precision.toString().split('.')[1].length);
};

export function toCommaSeparatedFixedValue(value, precision, placeholder) {
  if (!value && placeholder != null) return placeholder;
  return toCommaSeparatedNumber(roundToFixedPrecision(value, precision));
}

export const cutValueToPrecision = (value, precision) => {
  if (!value || !Number.isFinite(precision) || precision >= 1 || precision < 0) {
    return value;
  }

  if (precision === 0) {
    const [firstValuePart] = String(value).split('.');
    return firstValuePart;
  }

  const finalPrecision = precision.toString().split('.')[1].length;
  return Number(value).toFixed(finalPrecision);
};

export const truncToFixedLength = (value, fixedLength) => {
  if (!Number.isFinite(value) || !Number.isFinite(fixedLength) || fixedLength <= 0) {
    return value;
  }
  return Decimal(value).toFixed(fixedLength, Decimal.ROUND_DOWN);
};

export const truncToPrecision = (value, precision) => {
  if (!Number.isFinite(value) || !Number.isFinite(precision)) {
    return value;
  }
  if (precision >= 1) {
    return Math.trunc(value);
  }

  const finalPrecision = precision.toString().split('.')[1].length;
  return truncToFixedLength(value, finalPrecision);
};

export const roundExactlyOnPrecisionMatching = (value, precision, ignoreCounterPrecision = false) => {
  if (!precision || !Number.isFinite(Number(value))) {
    return value;
  }

  const [firstPart] = String(value).split('.');

  if ((ignoreCounterPrecision && precision === 1) || precision < 1) {
    return firstPart;
  }

  let actualPrecision = Math.floor(precision);
  if (precision === 1) {
    actualPrecision = Math.round(1 / COUNTER_PRECISION);
  }

  if (!String(value).match(getPrecisionTriggeringReg(actualPrecision))) {
    return value;
  }

  const [roundedFirstPart, roundedSecondPart] = String(cutValueToPrecision(String(value), 1 / actualPrecision)).split(
    '.',
  );

  return `${Number(firstPart) === Number(roundedFirstPart) ? firstPart : roundedFirstPart}.${roundedSecondPart}`;
};

export const roundTo3DigitsAfterDot = (value) => roundToPrecision(value, 1000);

// The fiscal year starts from January
export const getQuarterDates = (date) => {
  const getStartOfYear = () => moment(date).startOf('year');
  return [
    {
      startDate: getStartOfYear().format(),
      endDate: getStartOfYear().add(3, 'months').date(0).format(),
    },
    {
      startDate: getStartOfYear().add(3, 'months').format(),
      endDate: getStartOfYear().add(6, 'months').date(0).format(),
    },
    {
      startDate: getStartOfYear().add(6, 'months').format(),
      endDate: getStartOfYear().add(9, 'months').date(0).format(),
    },
    {
      startDate: getStartOfYear().add(9, 'months').format(),
      endDate: getStartOfYear().add(12, 'months').date(0).format(),
    },
  ];
};

export const expireTimeFunc = (expireType, expireTime, isShort = false) => {
  switch (Number(expireType)) {
    case EXPIRATION_TYPE_MAIN.DAY.ID:
    case EXPIRATION_TYPE_MAIN.WEEK.ID:
    case EXPIRATION_TYPE_MAIN.INFINITY.ID:
      return EXPIRATION_TYPE_VALUES[expireType];
    case EXPIRATION_TYPE_MAIN.CUSTOM.ID:
      return isShort ? formatDateTimeShortTypeWithoutSecond(expireTime) : formatDateTimeWithoutDivider(expireTime);
    default:
      return '-';
  }
};

export const getPortfolioPriceStepByServiceId = (serviceId, instrumentId, pricePrecision) => {
  if (!instrumentId) {
    return 1;
  }
  if (serviceId === FX) {
    return instrumentId.endsWith(COUNTRY_TYPE.JPY)
      ? FX_AP_GROUP_CHANGE_LOGIC_STEP_CROSS_YEN
      : FX_AP_GROUP_CHANGE_LOGIC_STEP_CROSS_CURRENCY;
  }
  if (serviceId === ETF) {
    return pricePrecision;
  }
  return FX_CFD_AP_GROUP_CHANGE_LOGIC_STEP;
};

export const getPortfolioChangeLogicStepByServiceId = (serviceId, pricePrecision) => {
  if (serviceId === ETF) {
    return pricePrecision;
  }
  return FX_CFD_AP_GROUP_CHANGE_LOGIC_STEP;
};

export const getPriceStep = (instrumentId) => {
  if (!instrumentId) {
    return 1;
  }
  return instrumentId.endsWith(COUNTRY_TYPE.JPY) ? INSTRUMENT_PRICE_STEP_WTH_JPY : INSTRUMENT_PRICE_STEP_WITHOUT_JPY;
};

export const getPriceStepByServiceId = (serviceId, instrumentId, pricePrecision) => {
  if (serviceId === FX) {
    return getPriceStep(instrumentId);
  }
  if (serviceId === ETF) {
    return pricePrecision;
  }
  return CFD_MIN_VALUE;
};

export const getAPQuantityStep = (instrumentId) => {
  if (!instrumentId) {
    return 0.1;
  }
  return instrumentId === INSTRUMENT_ZAR_AP_JPY ? QUANTITY_STEP.ONE : QUANTITY_STEP.ZERO_POINT_ONE;
};

export const getAPQuantityMin = (instrumentId) => {
  if (!instrumentId) {
    return 0.1;
  }
  return instrumentId === INSTRUMENT_ZAR_AP_JPY ? QUANTITY_STEP.ONE : QUANTITY_STEP.ZERO_POINT_ONE;
};

export const getAPValidateQuantityErrorMessage = (instrumentId) => {
  return instrumentId === INSTRUMENT_ZAR_AP_JPY
    ? VALIDATION_ERROR_AP_ORDER_QUANTITY_ONE
    : VALIDATION_ERROR_AP_ORDER_QUANTITY_ZERO_POINT_ONE;
};

export const getFxQuantityErrorMessage = (instrumentId, min, max) => {
  const step = getAPQuantityStep(instrumentId);
  return `${min}万以上${max}万以下、${step}万通貨単位でご設定ください。`;
};

export const getChangeManualTradeOrderValuesActionsArray = (orderType, sideId, sellPrice, buyPrice) => {
  switch (orderType) {
    case ORDER_TYPES.STANDARD.name: {
      return [
        changeCreateOrderValues({
          orderType,
          inputName: PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
        }),
      ];
    }
    case ORDER_TYPES.OCO.name: {
      return [
        changeCreateOrderValues({
          orderType,
          inputName: LIMIT_PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
        }),
        changeCreateOrderValues({
          orderType,
          inputName: STOP_PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
        }),
      ];
    }
    case ORDER_TYPES.IFD.name: {
      return [
        changeCreateOrderValues({
          orderType,
          inputName: PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
        }),
        changeCreateOrderValues({
          orderType,
          inputName: SETTLEMENT_PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? buyPrice : sellPrice,
        }),
      ];
    }
    case ORDER_TYPES.IFO.name: {
      return [
        changeCreateOrderValues({
          orderType,
          inputName: PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? sellPrice : buyPrice,
        }),
        changeCreateOrderValues({
          orderType,
          inputName: SETTLEMENT_LIMIT_PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? buyPrice : sellPrice,
        }),
        changeCreateOrderValues({
          orderType,
          inputName: SETTLEMENT_STOP_PRICE_INPUT,
          value: sideId === BUY_SELL_MAIN.SELL.ID ? buyPrice : sellPrice,
        }),
      ];
    }
    default: {
      return [];
    }
  }
};

export const getRefreshNonCommonStateActionsArrayForManualTrade = ({
  orderType,
  sellPrice,
  buyPrice,
  orderSettings,
  sideId,
}) => {
  const actionsArray = [resetCreateOrderNonCommonValues()];
  if (orderType === ORDER_TYPES.IFD.name || orderType === ORDER_TYPES.IFO.name) {
    actionsArray.push(
      changeCreateOrderValues({
        orderType,
        inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
        value: Number(orderSettings.expirationType),
      }),
    );
  }
  actionsArray.push(...getChangeManualTradeOrderValuesActionsArray(orderType, sideId, sellPrice, buyPrice));
  return actionsArray;
};

export function resolveInstrumentDefaultQuantity(instrumentId, defaultQuantity) {
  const defaultQuantityMultiplier = DEFAULT_QUANTITY_MULTIPLIERS[instrumentId];
  return defaultQuantityMultiplier ? defaultQuantity * defaultQuantityMultiplier : defaultQuantity;
}

export const getRefreshStateActionsArrayForManualTrade = ({
  orderType,
  sellPrice,
  buyPrice,
  orderSettings,
  keepValues,
  instrumentId,
}) => {
  let actionsArray = [];

  if (!keepValues) {
    actionsArray = [
      resetCreateOrderValues(),
      changeSelectedSide({
        id: Number(
          getDefaultValuesFromLocalStorage({
            key: KEY_FOR_DEFAULT_SELECT_SIDE,
            defaultValue: BUY_SELL_MAIN.BUY.CHART_ID,
          }),
        ),
      }),
      ...Object.values(ORDER_TYPES).reduce((actions, type) => {
        const setInputValueAction = (inputName, value) =>
          changeCreateOrderValues({ orderType: type.name, inputName, value });

        actions.push(
          setInputValueAction(
            COUNT_INPUT,
            Number(resolveInstrumentDefaultQuantity(instrumentId, orderSettings.quantity)),
          ),
        );

        if (Object.values(type.inputs).includes(EXPIRATION_TYPE_INPUT)) {
          actions.push(setInputValueAction(EXPIRATION_TYPE_INPUT, Number(orderSettings.expirationType)));
        }
        return actions;
      }, []),
    ];
  }

  if (orderType === ORDER_TYPES.IFD.name || orderType === ORDER_TYPES.IFO.name) {
    actionsArray.push(
      changeCreateOrderValues({
        orderType,
        inputName: SETTLEMENT_EXPIRATION_TYPE_INPUT,
        value: Number(orderSettings.expirationType),
      }),
    );
  }

  const buySellDefaultId =
    Number(
      getDefaultValuesFromLocalStorage({
        key: KEY_FOR_DEFAULT_SELECT_SIDE,
        defaultValue: BUY_SELL_MAIN.BUY.CHART_ID,
      }),
    ) === BUY_SELL_MAIN.BUY.CHART_ID
      ? BUY_SELL_MAIN.BUY.ID
      : BUY_SELL_MAIN.SELL.ID;

  actionsArray.push(...getChangeManualTradeOrderValuesActionsArray(orderType, buySellDefaultId, sellPrice, buyPrice));
  return actionsArray;
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const checkMailsSelected = (secondaryMailAddress, mailActions) => {
  if (!secondaryMailAddress) {
    return {
      primary: true,
      secondary: false,
    };
  }

  return {
    primary: mailActions.some((item) => Boolean(item.sendPrimary)),
    secondary: mailActions.some((item) => Boolean(item.sendSecondary)),
  };
};

export const processMailList = (primaryMailAddress, secondaryMailAddress, mailActions) => {
  const mailingList = [];
  const areMailsSelected = checkMailsSelected(secondaryMailAddress, mailActions);
  const maskingNum = 3;

  if (primaryMailAddress) {
    const primaryMailAddressArray = primaryMailAddress.split('');
    for (let i = primaryMailAddressArray.indexOf('@') - maskingNum; i < primaryMailAddressArray.length; i += 1) {
      if (primaryMailAddress[i] === '@') {
        continue; // eslint-disable-line
      }
      if (i > primaryMailAddressArray.indexOf('@') + maskingNum) {
        break;
      }
      primaryMailAddressArray[i] = '*';
    }
    const maskPrimaryMailAddress = primaryMailAddressArray.join('');
    mailingList.push({
      label: `${EMAIL_LIST_TEXTS.part1}1${EMAIL_LIST_TEXTS.part2}${maskPrimaryMailAddress}${EMAIL_LIST_TEXTS.part3}`,
      selected: areMailsSelected.primary,
      disabled: !secondaryMailAddress, // disabled if it's the only 1 email
    });
  }

  if (secondaryMailAddress) {
    const secondaryMailAddressArray = secondaryMailAddress.split('');
    for (let s = secondaryMailAddressArray.indexOf('@') - maskingNum; s < secondaryMailAddressArray.length; s += 1) {
      if (secondaryMailAddressArray[s] === '@') {
        continue; // eslint-disable-line
      }
      if (s > secondaryMailAddressArray.indexOf('@') + maskingNum) {
        break;
      }
      secondaryMailAddressArray[s] = '*';
    }
    const maskSecondaryMailAddress = secondaryMailAddressArray.join('');
    mailingList.push({
      label: `${EMAIL_LIST_TEXTS.part1}2${EMAIL_LIST_TEXTS.part2}${maskSecondaryMailAddress}${EMAIL_LIST_TEXTS.part3}`,
      selected: areMailsSelected.secondary,
      disabled: false,
    });
  }

  return Object.entries(mailingList).map(([id, item]) => ({
    id: Number(id),
    label: item.label,
    selected: item.selected,
    disabled: item.disabled,
  }));
};

export const checkMailActionSelected = (action) => {
  if (action) return Boolean(action.sendPrimary) || Boolean(action.sendSecondary);
  return false;
};

export const sortMailActions = (mailActions) =>
  mailActions.map((item) => {
    const selected = checkMailActionSelected(item);
    const action = MAIL_ACTIONS.find((el) => [el.actionId, el.additionalActionId].includes(item.actionId));

    return {
      id: item.actionId,
      label: action.label,
      selected,
      disabled: action.disabled,
      hidden: action.hidden,
    };
  }, []);

export const processMailActionsBeforeUpdating = (mailingList, mailActions) => {
  const shouldSetAllPrimaryFalse = !mailingList[0].selected;
  const shouldSetAllSecondaryFalse = !mailingList[1] || !mailingList[1].selected;

  return mailActions.map((item) => ({
    actionId: item.id,
    sendPrimary: shouldSetAllPrimaryFalse ? false : item.selected,
    sendSecondary: shouldSetAllSecondaryFalse ? false : item.selected,
  }));
};

export const calculateUnrealisedProfitLoss = (
  unrealizedProfitLossByInstrumentId,
  apGroupId,
  buySell,
  apGroupIdList = [],
  isTechCalc = false,
) => {
  if (!unrealizedProfitLossByInstrumentId || (!apGroupId && !buySell)) {
    return null;
  }

  let result = new Decimal(0);

  if (!isTechCalc) {
    result = Object.values(unrealizedProfitLossByInstrumentId).reduce((sum, item) => {
      if (!Number.isFinite(item.unrealizedProfitLoss)) return sum;

      const shouldAdd = apGroupId ? apGroupId === item.apGroupId : !item.apGroupId && item.side === buySell;

      return shouldAdd ? Decimal.add(sum || 0, item.unrealizedProfitLoss) : sum;
    }, null);
  } else {
    result = Object.values(unrealizedProfitLossByInstrumentId).reduce((sum, item) => {
      if (!Number.isFinite(item.unrealizedProfitLoss)) return sum;

      const apGroupIdInList = apGroupIdList.find((e) => e === item.apGroupId);
      const shouldAdd = apGroupIdInList ? apGroupIdInList === item.apGroupId : !item.apGroupId && item.side === buySell;

      return shouldAdd ? Decimal.add(sum || 0, item.unrealizedProfitLoss) : sum;
    }, null);
  }

  return result === null ? null : Number(result);
};

export const calculateQuantity = (
  unrealizedProfitLossByInstrumentId,
  apGroupId,
  buySell,
  apGroupIdList = [],
  isTechCalc = false,
) => {
  if (!unrealizedProfitLossByInstrumentId || (!apGroupId && !buySell)) {
    return null;
  }

  let result = new Decimal(0);

  if (!isTechCalc) {
    result = Object.values(unrealizedProfitLossByInstrumentId).reduce((sum, item) => {
      if (!Number.isFinite(item.unrealizedProfitLoss)) return sum;

      const shouldAdd = apGroupId ? apGroupId === item.apGroupId : !item.apGroupId && item.side === buySell;

      return shouldAdd ? Decimal.add(sum || 0, item.quantity) : sum;
    }, null);
  } else {
    result = Object.values(unrealizedProfitLossByInstrumentId).reduce((sum, item) => {
      if (!Number.isFinite(item.quantity)) return sum;

      const apGroupIdInList = apGroupIdList.find((e) => e === item.apGroupId);
      const shouldAdd = apGroupIdInList ? apGroupIdInList === item.apGroupId : !item.apGroupId && item.side === buySell;

      return shouldAdd ? Decimal.add(sum || 0, item.quantity) : sum;
    }, null);
  }

  return result === null ? null : Number(result);
};

export function hasPrecision(value, precision) {
  const [, fractalPart] = String(precision).split('.');
  const precisionDigit = fractalPart?.length || 0;
  const additionalDigitForRounding = 3;

  const processedValue = Number((value / precision).toFixed(precisionDigit + additionalDigitForRounding));
  return Math.trunc(processedValue) === processedValue;
}

export const ORDER_DETAILS_EDIT_HELPERS = {
  getNumberValue: (value) => value ?? '',
  convertToNumberOrEmptyString: (value) => {
    if (value === null) return '';

    if (value === '') return value;

    const convertedValue = Number(value);
    return Number.isFinite(convertedValue) ? convertedValue : 0;
  },
  createValidateFunction:
    ({
      inputName,
      errorMessage,
      minValue,
      maxValue,
      valuePrecision,
      changeErrorFunction = () => {},
      additionalCheck = true,
      additionalCheckErrorMessage = '',
      additionalCheckFieldName = '',
      skippIfEmptyString = false,
      exclusiveMin = false,
      exclusiveMax = false,
    }) =>
    (newValue) => {
      const validateMinValue = exclusiveMin ? newValue > minValue : newValue >= minValue;
      const validateMaxValue = exclusiveMax ? newValue < maxValue : newValue <= maxValue;

      const hasInnerError = !(
        (validateMinValue && validateMaxValue && hasPrecision(newValue, valuePrecision)) ||
        (skippIfEmptyString && newValue === '')
      );
      const hasError = hasInnerError || !additionalCheck;

      const displayedError = hasInnerError ? errorMessage : additionalCheckErrorMessage;

      changeErrorFunction((prevVal) => {
        const filteredPrevVal = prevVal.filter(
          (item) =>
            (item.inputName !== inputName && item.inputName !== additionalCheckFieldName) ||
            (item.inputName === additionalCheckFieldName && item.errorMessage !== additionalCheckErrorMessage),
        );

        if (!hasError || !displayedError) {
          return filteredPrevVal;
        }

        const result = [...filteredPrevVal, { inputName, errorMessage: displayedError }];
        if (!additionalCheck && additionalCheckErrorMessage && additionalCheckFieldName) {
          result.push({ inputName: additionalCheckFieldName, errorMessage: additionalCheckErrorMessage });
        }

        return result;
      });

      return hasError;
    },
};

export const calculateMaxItemForCart = ({ strategyList, instrumentList, useDefaultSets = false }) => {
  if (!Object.keys(instrumentList ?? {})?.length || !strategyList?.length) {
    return Infinity;
  }

  return strategyList?.reduce((result, { strategySets, strategyDetail: { instrumentId, itemList } }) => {
    const { buyQuantityMax, sellQuantityMax, allowBuyFlg, allowSellFlg } =
      instrumentList?.[instrumentId]?.settings?.[TRADE_METHODS.AP.ID] || {};

    const { itemMaxSellQuantity, itemMaxBuyQuantity } = itemList.reduce(
      (acc, item) => {
        const newAcc = { ...acc };

        if (Number(item.side) === BUY_SELL_MAIN.SELL.ID) {
          newAcc.itemMaxSellQuantity =
            item.quantity > acc.itemMaxSellQuantity ? item.quantity : acc.itemMaxSellQuantity;
        } else {
          newAcc.itemMaxBuyQuantity = item.quantity > acc.itemMaxBuyQuantity ? item.quantity : acc.itemMaxBuyQuantity;
        }

        return newAcc;
      },
      { itemMaxSellQuantity: null, itemMaxBuyQuantity: null },
    );

    const strategySetsValue = useDefaultSets ? CART_DEFAULT_SETS : strategySets;

    const minQuantityForStrategy = Math.floor(
      Math.min(
        allowBuyFlg ? buyQuantityMax / itemMaxBuyQuantity : Infinity,
        allowSellFlg ? sellQuantityMax / itemMaxSellQuantity : Infinity,
      ) / strategySetsValue,
    );

    return Math.min(minQuantityForStrategy, result);
  }, Infinity);
};

export const reorderForDragAndDrop = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getDecimalPlace = (precision) => {
  return String(precision).split('.')[1].length;
};

export const createValidationErrorMessageAPOrderCounterPriceBuy = (price, precision) =>
  `${roundToFixedPrecision(price * PRICE_MIN_MULTIPLIER, precision)}より高い価格、${roundToFixedPrecision(
    price * PRICE_MAX_MULTIPLIER,
    precision,
  )}より低い価格、小数点${getDecimalPlace(precision)}位でご設定ください。`;
export const createValidationErrorMessageAPOrderCounterPriceSell = (price, precision) =>
  `${roundToFixedPrecision(price * PRICE_MIN_MULTIPLIER, precision)}より高い価格、${roundToFixedPrecision(
    price * PRICE_MAX_MULTIPLIER,
    precision,
  )}より低い価格、小数点${getDecimalPlace(precision)}位でご設定ください。`;
export const createValidationErrorMessageAPOrderEntryPrice1Buy = (price, precision) =>
  `${roundToFixedPrecision(price * PRICE_MIN_MULTIPLIER, precision)}より高い価格、${roundToFixedPrecision(
    price * PRICE_MAX_MULTIPLIER,
    precision,
  )}より低い価格、小数点${getDecimalPlace(precision)}位でご設定ください。`;
export const createValidationErrorMessageAPOrderEntryPrice1Sell = (price, precision) =>
  `${roundToFixedPrecision(price * PRICE_MIN_MULTIPLIER, precision)}より高い価格、${roundToFixedPrecision(
    price * PRICE_MAX_MULTIPLIER,
    precision,
  )}より低い価格、小数点${getDecimalPlace(precision)}位でご設定ください。`;
export const createValidationErrorMessageAPOrderEntryPrice2Buy = (price, precision) =>
  `${roundToFixedPrecision(price * PRICE_MIN_MULTIPLIER, precision)}より高い価格、${roundToFixedPrecision(
    price * PRICE_MAX_MULTIPLIER,
    precision,
  )}より低い価格、小数点${getDecimalPlace(precision)}位でご設定ください。`;
export const createValidationErrorMessageAPOrderEntryPrice2Sell = (price, precision) =>
  `${roundToFixedPrecision(price * PRICE_MIN_MULTIPLIER, precision)}より高い価格、${roundToFixedPrecision(
    price * PRICE_MAX_MULTIPLIER,
    precision,
  )}より低い価格、小数点${getDecimalPlace(precision)}位でご設定ください。`;
export const createValidationErrorMessageAPR67ProfitMarginSell = (price, precision) =>
  `設定した買い決済注文が現在値${roundToFixedPrecision(price, precision)}よりも高いため、発注できません。`;
export const createValidationErrorMessageAPR67ProfitMarginBuy = (price, precision) =>
  `設定した売り決済注文が現在値${roundToFixedPrecision(price, precision)}よりも低いため、発注できません。`;
export const createValidationErrorMessageAPR67LossCutWidthSell = (price, precision) =>
  `設定した買い決済注文が現在値${roundToFixedPrecision(price, precision)}よりも低いため、発注できません。`;
export const createValidationErrorMessageAPR67LossCutWidthBuy = (price, precision) =>
  `設定した売り決済注文が現在値${roundToFixedPrecision(price, precision)}よりも高いため、発注できません。`;

const VALIDATE_ORDER_SETTINGS_COUNT_CONST = {
  [ETF]: {
    max: QUANTITY_MAXES[ETF],
    min: QUANTITY_MINS[ETF],
    getErrorMessage: (max, min) => `${min}口以上${max}口以下、${QUANTITY_COUNTER_MIN_INT}口単位でご設定ください。`,
  },
  [FX]: {
    max: QUANTITY_MAXES[FX],
    min: QUANTITY_MINS[FX],
    getErrorMessage: (max, min) => `${min}万以上${max}万以下、${QUANTITY_PRECISION}万通貨単位でご設定ください。`,
  },
  [CFD]: {
    max: QUANTITY_MAXES[CFD],
    min: QUANTITY_MINS[CFD],
    // TODO CFD 暫定で ETF のコピー
    getErrorMessage: (max, min) => `${min}Lot以上${max}Lot以下、${QUANTITY_PRECISION}Lot単位でご設定ください。`,
  },
};

export function validateOrderSettingsCount(value, serviceId) {
  const { max, min, getErrorMessage } = VALIDATE_ORDER_SETTINGS_COUNT_CONST[serviceId];

  if (!NUMBER_VALIDATION_REG.test(String(value)) || (serviceId === ETF && String(value).includes('.'))) {
    return { isValid: false, errorMessage: VALIDATION_ERROR_EMPTY_FIELD };
  }

  const isValid = min <= value && value <= max;
  return { isValid, errorMessage: isValid ? '' : getErrorMessage(max, min) };
}

const validTimeDifference = 59;
export const validateSelectedTime = ({ date, time }) => {
  const currentDate = moment().second(0);
  const selectedDate = moment(createDateStringByDateAndTime(date, time));

  const timeDiffInSeconds = selectedDate.diff(currentDate, 'seconds');

  return timeDiffInSeconds >= validTimeDifference;
};

export const validateSettlementSelectedTime = ({
  expirationType,
  expirationDateWithTime,
  settlementType,
  settlementTypeWithDate,
  serviceId,
}) => {
  const firstTime = getTimeValueByExpirationType(expirationType, expirationDateWithTime, serviceId);
  const secondTime = getTimeValueByExpirationType(settlementType, settlementTypeWithDate, serviceId);

  if (settlementType === EXPIRATION_TYPE_MAIN.INFINITY.ID) {
    return true;
  }

  if (
    (expirationType === EXPIRATION_TYPE_MAIN.INFINITY.ID && settlementType !== EXPIRATION_TYPE_MAIN.INFINITY.ID) ||
    getTimeSecondsDifference(firstTime, secondTime) > 0
  ) {
    return false;
  }

  return true;
};

const EXEC_TIME = 'execTime';
const QUANTITY = 'quantity';
const SETTLING_QUANTITY = 'settlingQuantity';
const TRADE_PRICE = 'tradePrice';
const DIVIDEND = 'dividend';
const UNREALIZED_SWAP_PL = 'unrealizedSwapPl';
const PL = 'pl';
const INSTRUMENT_ID = 'instrumentId';
const TRADE_METHOD = 'tradeMethod';
const SIDE = 'side';

const sortedColumnsWithStringValues = [EXEC_TIME];
const sortedColumnsWithNumberValues = [QUANTITY, SETTLING_QUANTITY, TRADE_PRICE, UNREALIZED_SWAP_PL, DIVIDEND];
const columnWithPLData = PL;
const columnWithInstrumentId = INSTRUMENT_ID;

export const sortString = ({ a, b, sortBy, sortDir }) => {
  if (sortDir === SORT_ASCENDING) {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    }
    if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    return 0;
  }
  if (a[sortBy] < b[sortBy]) {
    return 1;
  }
  if (a[sortBy] > b[sortBy]) {
    return -1;
  }
  return 0;
};

const sortNumber = ({ a, b, sortBy, sortDir, nullToZero = false }) => {
  const aVal = nullToZero && a[sortBy] === null ? 0 : a[sortBy];
  const bVal = nullToZero && b[sortBy] === null ? 0 : b[sortBy];
  if (sortDir === SORT_ASCENDING) {
    if (!Number.isFinite(aVal)) {
      return -1;
    }
    if (!Number.isFinite(bVal)) {
      return 1;
    }
    const valueDifference = aVal - bVal;
    return valueDifference === 0 ? sortString({ a, b, sortBy: EXEC_TIME, sortDir: SORT_DESCENDING }) : valueDifference;
  }
  if (!Number.isFinite(aVal)) {
    return 1;
  }
  if (!Number.isFinite(bVal)) {
    return -1;
  }
  const oppositeValueDifference = bVal - aVal;
  return oppositeValueDifference === 0
    ? sortString({ a, b, sortBy: EXEC_TIME, sortDir: SORT_DESCENDING })
    : oppositeValueDifference;
};

const sortPl = ({ a, b, plRef, sortDir }) => {
  if (!plRef.current[a.instrumentId]) {
    return 0;
  }
  if (sortDir === SORT_ASCENDING) {
    if (!plRef.current[a.instrumentId][a.positionId]) {
      return -1;
    }
    if (!plRef.current[b.instrumentId][b.positionId]) {
      return 1;
    }
    return (
      plRef.current[a.instrumentId][a.positionId].unrealizedProfitLoss -
      plRef.current[b.instrumentId][b.positionId].unrealizedProfitLoss
    );
  }
  if (!plRef.current[a.instrumentId][a.positionId]) {
    return 1;
  }
  if (!plRef.current[b.instrumentId][b.positionId]) {
    return -1;
  }
  return (
    plRef.current[b.instrumentId][b.positionId].unrealizedProfitLoss -
    plRef.current[a.instrumentId][a.positionId].unrealizedProfitLoss
  );
};

const sortInstrumentId = ({ a, b, sortBy, sortDir }) => {
  let indexDifference = 0;
  const removedA = removeInstrumentIdSuffix(a[sortBy]);
  const removedB = removeInstrumentIdSuffix(b[sortBy]);

  if (removedA > removedB) {
    indexDifference = 1;
  }
  if (removedA < removedB) {
    indexDifference = -1;
  }

  if (indexDifference === 0) {
    // 注文種類ソート
    indexDifference = sortString({
      a,
      b,
      sortBy: TRADE_METHOD,
      sortDir: sortDir === SORT_ASCENDING ? SORT_ASCENDING : SORT_DESCENDING,
    });
    // 売買ソート
    if (indexDifference === 0) {
      indexDifference = sortString({
        a,
        b,
        sortBy: SIDE,
        sortDir: sortDir === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING,
      });
      // 約定日時ソート
      if (indexDifference === 0) {
        return sortString({ a, b, sortBy: EXEC_TIME, sortDir: SORT_DESCENDING });
      }
    }
  }

  if (sortDir === SORT_ASCENDING) {
    return indexDifference;
  }
  return -indexDifference;
};

export const sortPositionsTable = ({ a, b, sortDir, sortBy, plRef }) => {
  if (sortedColumnsWithStringValues.includes(sortBy)) {
    return sortString({ a, b, sortBy, sortDir });
  }
  if (sortedColumnsWithNumberValues.includes(sortBy)) {
    return sortNumber({ a, b, sortBy, sortDir, nullToZero: true });
  }
  if (sortBy === columnWithPLData) {
    return sortPl({ a, b, sortDir, plRef });
  }
  if (sortBy === columnWithInstrumentId) {
    return sortInstrumentId({ a, b, sortBy, sortDir });
  }
  return 0;
};

export const parsedIMSError = (e) => {
  const parsedError = e.message.match(IMS_REG);

  if (parsedError) {
    switch (parsedError[0]) {
      case IMS_200A: {
        return IMS_200A_ERROR_MESSAGE;
      }
      case IMS_200B: {
        return IMS_200B_ERROR_MESSAGE;
      }
      case IMS_201: {
        return IMS_201_ERROR_MESSAGE;
      }
      case IMS_202: {
        return IMS_202_ERROR_MESSAGE;
      }
      case IMS_203: {
        return IMS_203_ERROR_MESSAGE;
      }
      case IMS_800: {
        return IMS_800_ERROR_MESSAGE;
      }
      default: {
        return IMS_XXX_ERROR_MESSAGE;
      }
    }
  }

  return DISPLAYING_ERROR_LOGIN;
};

export const calculateSpread = ({ buyPrice, sellPrice, instrumentId, instrumentList, alwaysReturnValue = false }) => {
  if (!instrumentId || !buyPrice || !sellPrice || !instrumentList) {
    return '-';
  }

  let multiplier;
  let realInstrumentId;

  const fxConversionAsset = [ETF, CFD];
  const isConversionInstrumentId = fxConversionAsset.some((val) => instrumentId.includes(val));
  if (isConversionInstrumentId) {
    realInstrumentId = addSuffixInstrumentId(instrumentId.substr(instrumentId.indexOf('.') + 1));
  } else {
    realInstrumentId = instrumentId;
  }

  const serviceId = getService(realInstrumentId, instrumentList);
  if (serviceId !== FX) {
    multiplier = 1;
  } else {
    multiplier = instrumentId.endsWith(COUNTRY_TYPE.JPY) ? SPREAD_YEN_PAIR_MULTIPLIER : SPREAD_NOT_YEN_PAIR_MULTIPLIER;
  }

  const precision = getSpreadPrecision(realInstrumentId, instrumentList);
  const calculationResult = (buyPrice - sellPrice) * multiplier;
  const finalValue = precision ? roundToFixedPrecision(calculationResult, precision) : calculationResult.toFixed(1);

  if (alwaysReturnValue) return finalValue;

  return finalValue > SPREAD_MAX_VALUE ? SPREAD_MORE_THEN_MAX_VALUE : finalValue;
};

export const getServiceQuantityUnit = (serviceId) => {
  switch (serviceId) {
    case FX:
      return '万';
    case ETF:
      return '口';
    case CFD:
      return 'Lot';
    default:
      return '万/Lot/口';
  }
};

export const getMultiServiceQuantityUnit = (serviceIds, isMultiService) => {
  if (!isMultiService) {
    return `${AMOUNT_UNIT_MAP[serviceIds[0]]}`;
  }
  const swapInterestLabel = serviceIds.map((serviceId) => AMOUNT_UNIT_MAP[serviceId]).join('/');
  return `${swapInterestLabel}`;
};

export const combineWithInstruments = (initArray, instrumentList, searchProp) => {
  return initArray.map((item) => {
    const instrumentInfo = Object.values(instrumentList ?? {}).find(
      (instrument) => item[searchProp] === instrument.instrumentId,
    );

    /* eslint-disable-next-line no-param-reassign */
    if (item.children) item.children = combineWithInstruments(item.children, instrumentList, searchProp);

    return {
      ...item,
      ...instrumentInfo,
    };
  });
};

export const getBuilderPriceStep = (currencyPair) =>
  currencyPair.endsWith(COUNTRY_TYPE.JPY) ? ENTRY_PRICE_PAIRED_YEN_STEP : ENTRY_PRICE_NOT_PAIRED_YEN_STEP;

export const getBuilderRoundedOptionValue = (value, precision) => {
  if ((!precision && precision !== 0) || value === '') return value;
  if (precision === 0) return cutValueToPrecision(value, precision);
  const precisionConversion = Number(Decimal.pow(10, precision));
  return roundExactlyOnPrecisionMatching(value, precisionConversion);
};

export const getSingleAPGroupErrorMessage = (hasPosition, hasOrder, isDeleting) => {
  let errorMessage = null;
  const hasPositionError = isDeleting
    ? '自動売買に建玉があるため削除できません。'
    : `当該自動売買注文は建玉保有中のため変更できません。
	【照会画面・建玉照会】より建玉をご確認ください。`;
  const hasOrderError = isDeleting
    ? '自動売買に新規注文があるため削除できません。'
    : `当該自動売買注文は新規注文が発注中のため変更できません。
	【照会画面・注文照会】にて注文取消し後、変更を行ってください。`;

  if (isDeleting && hasPosition && hasOrder) {
    errorMessage = `${hasPositionError}`;
  } else if (hasPosition) {
    errorMessage = hasPositionError;
  } else if (hasOrder) {
    errorMessage = hasOrderError;
  }

  return errorMessage;
};

export const systemErrorHandler = (isErrorOccurred) => {
  if (isErrorOccurred) {
    saveDefaultValuesFromLocalStorage({ key: KEY_FOR_SYSTEM_ERROR, value: true });

    // check if web app is opened by mobile app
    const urlParams = new URLSearchParams(window.location.search.substr(1));
    const isMobile = urlParams.get('isMobile');

    if (!isMobile) window.location.replace('/home');
  } else saveDefaultValuesFromLocalStorage({ key: KEY_FOR_SYSTEM_ERROR, value: false });
};

export function getFxCfdQuantityStep(value, isIncrement) {
  if ((isIncrement && value >= QUANTITY_COUNTER_MIN_INT) || (value > QUANTITY_COUNTER_MIN_INT && !isIncrement))
    return QUANTITY_STEP.ONE;
  return QUANTITY_STEP.ZERO_POINT_ONE;
}

export function getPercentage(part, whole, decimalDigits = 2) {
  try {
    return Number(Decimal.div(part, whole).mul(100).toFixed(decimalDigits));
  } catch {
    return 0;
  }
}
export function getFlooredPercentage(part, whole) {
  try {
    return Number(Math.floor(Decimal.div(part, whole).mul(100) * 100) / 100);
  } catch {
    return 0;
  }
}

export const getFormattedPercentage = (part, whole, decimalDigits) =>
  part && whole ? `${getFlooredPercentage(part, whole, decimalDigits).toFixed(decimalDigits, Decimal.DOWN)}%` : '-';

export function multiplyAndRoundDecimalsIfAllExist(operands, decimalDigits = 3) {
  if (operands.some((operand) => !operand)) return '-';

  return Number(
    operands.reduce((acc, operand) => acc.mul(operand), new Decimal(1)).toFixed(decimalDigits, Decimal.DOWN),
  );
}

// trimming commas as well, if number was formatted with separators
export const trimFrontZeros = (value) => String(value).replace(FRONT_ZEROS_AND_COMMAS_REG, '');

export const UNSIGNED_NUMBER_REG = /^(?<number>[\d]*[.]?[\d]*)$/;
export const NUMBER_REG = /^(?<sign>[-])?(?<number>[\d]*[.]?[\d]*)$/;

const NOT_INT_CHAR_REG = /[^\d]/g;
const withOnlyDigits = (value) => value.replace(NOT_INT_CHAR_REG, '');

export function setNumberInputValue(params) {
  const { setValue, validate, min, max, allowDecimal, allowNegative } = params;
  let { value } = params;

  if (!allowDecimal) value = withOnlyDigits(value);

  const numberFormatReg = allowNegative ? NUMBER_REG : UNSIGNED_NUMBER_REG;
  const numberFormatMatch = String(value).match(numberFormatReg);

  if (!numberFormatMatch) return;

  validate(value);

  if (numberExists(max) && Number(value) > max) value = String(max);
  else if (numberExists(min) && Number(value) < min) value = String(min);
  else {
    const { sign = '', number } = numberFormatMatch.groups;
    value = `${sign}${trimFrontZeros(number)}`;
  }

  setValue(value);
}

export const minReportsYear = (serviceId) => {
  if (serviceId === ETF) {
    return MIN_REPORTS_YEAR_ETF;
  }
  if (serviceId === CFD) {
    return MIN_REPORTS_YEAR_CFD;
  }
  return MIN_REPORTS_YEAR_FX;
};

export const lastMonthValue = () => {
  return getPreviousMonthWorkday().getMonth();
};

export const yearOptionList = (serviceId) => {
  const options = [];
  const minYear = minReportsYear(serviceId);
  const maxYear = new Date().getFullYear();
  for (let year = maxYear; year >= minYear; year -= 1) {
    options.push({ label: String(year), value: year });
  }
  return options;
};

export const currentQuarter = () => {
  const month = moment().month();
  switch (month) {
    case 0:
    case 1:
    case 2:
      return quarterOptions[0].value;
    case 3:
    case 4:
    case 5:
      return quarterOptions[1].value;
    case 6:
    case 7:
    case 8:
      return quarterOptions[2].value;
    case 9:
    case 10:
    case 11:
    default:
      return quarterOptions[3].value;
  }
};

export { getYoutubeId } from './youtube';

export const performanceMap = {
  excellent: '絶好調',
  verygood: '好調',
  good: '横這い',
  bad: '不調',
};

export const getLastYearRange = () => ({
  startTime: moment().tz('Asia/Tokyo').subtract(1, 'year').format(CHART_DATA_FORMAT),
  endTime: moment().tz('Asia/Tokyo').format(CHART_DATA_FORMAT),
});

export const getCircleNum = (num) => {
  if (num <= 0 || num >= 51) return num;

  if (num <= 20) {
    const base = '①'.charCodeAt(0);
    return String.fromCharCode(base + num - 1);
  }
  if (num <= 35) {
    const base = '㉑'.charCodeAt(0);
    return String.fromCharCode(base + num - 21);
  }
  const base = '㊱'.charCodeAt(0);
  return String.fromCharCode(base + num - 36);
};

export const calcEffectiveRatio = ({
  effectiveMargin,
  positionRequiredMargin,
  orderingRequiredMargin,
  consumedMargin = 0,
}) => {
  return Decimal.div(
    effectiveMargin || 0,
    Decimal.add(positionRequiredMargin || 0, Decimal.add(orderingRequiredMargin || 0, consumedMargin || 0)),
  ).toNumber();
};

export const getBalanceMeterAttention = ({
  effectiveMargin,
  positionRequiredMargin,
  orderingRequiredMargin,
  consumedMargin = 0,
}) => {
  const ratio = calcEffectiveRatio({ effectiveMargin, positionRequiredMargin, orderingRequiredMargin, consumedMargin });

  if (ratio < BALANCE_METER_ATTENTION.danger.basePoint) {
    return BALANCE_METER_ATTENTION.danger;
  }
  if (ratio < BALANCE_METER_ATTENTION.warning.basePoint) {
    return BALANCE_METER_ATTENTION.warning;
  }
  if (ratio < BALANCE_METER_ATTENTION.aggressive.basePoint) {
    return BALANCE_METER_ATTENTION.aggressive;
  }
  if (ratio < BALANCE_METER_ATTENTION.veryGood.basePoint) {
    return BALANCE_METER_ATTENTION.veryGood;
  }
  return BALANCE_METER_ATTENTION.conservative;
};

export const getQuantityPrecision = ({ instrumentId, instrumentList }) => {
  // TODO 正しくは quantityPrecision なのだが、ここを直すと呼び出し元の表示が常に 0 になってしまう
  return instrumentList?.[instrumentId]?.settings?.[TRADE_METHODS.MANUAL.ID]?.quantity;
};

export const add = (value1, value2) => {
  const a = isNumber(value1) ? value1 : 0;
  const b = isNumber(value2) ? value2 : 0;
  return Decimal.add(a, b).toNumber();
};

export const isObjectEmpty = (value) => {
  return !Object.keys(value).length;
};
